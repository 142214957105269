exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-neuro-lab-tsx": () => import("./../../../src/pages/neuro-lab.tsx" /* webpackChunkName: "component---src-pages-neuro-lab-tsx" */),
  "component---src-pages-neuro-marketing-tsx": () => import("./../../../src/pages/neuro-marketing.tsx" /* webpackChunkName: "component---src-pages-neuro-marketing-tsx" */),
  "component---src-pages-our-ethicals-tsx": () => import("./../../../src/pages/our-ethicals.tsx" /* webpackChunkName: "component---src-pages-our-ethicals-tsx" */),
  "component---src-pages-our-policy-tsx": () => import("./../../../src/pages/our-policy.tsx" /* webpackChunkName: "component---src-pages-our-policy-tsx" */),
  "component---src-pages-why-neuroreflect-tsx": () => import("./../../../src/pages/why-neuroreflect.tsx" /* webpackChunkName: "component---src-pages-why-neuroreflect-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/Templates/Blog.Post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-solution-page-post-tsx": () => import("./../../../src/Templates/Solution.Page.Post.tsx" /* webpackChunkName: "component---src-templates-solution-page-post-tsx" */)
}

